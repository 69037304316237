import { useSelector, useDispatch } from 'react-redux';
import { selectPlaceTab } from '../actions';

import { FlexDiv } from '../components/FlexDiv';
import { Container } from '../components/Container';
import SpeechBubble from '../components/SpeechBubble';
import ProfileImage from '../components/ProfileImage';
import robert from '../assets/robert.png';
import Button from '../components/Button';
import Place, { SecondaryPlace } from '../components/Place';
import { topTen, dayTrips, overnightTrips } from '../data/placesData';

const ThingsToDo = () => {
    const selectedTab = useSelector(state => state.selectPlaceTab);
    const dispatch = useDispatch();

    return (
        <Container>
            <FlexDiv center column>
                <FlexDiv center column speechBubble>
                    <ProfileImage img={robert} />
                    <SpeechBubble size="sm" type="top-left">
                        <p>
                            These are some activites we can recommend in and
                            around Munich while you visit!
                        </p>
                    </SpeechBubble>
                </FlexDiv>
                <FlexDiv center centerAlign>
                    <Button
                        text="Munich Top 10"
                        onClick={() => dispatch(selectPlaceTab('top-ten'))}
                        type={selectedTab === 'top-ten' && 'primary'}
                    />
                    <Button
                        text="Day Trips"
                        onClick={() => dispatch(selectPlaceTab('day-trips'))}
                        type={selectedTab === 'day-trips' && 'primary'}
                    />
                    <Button
                        text="Overnight Trips"
                        onClick={() =>
                            dispatch(selectPlaceTab('overnight-trips'))
                        }
                        type={selectedTab === 'overnight-trips' && 'primary'}
                    />
                </FlexDiv>
                <FlexDiv column center centerAlign>
                    {selectedTab === 'top-ten' && (
                        <div style={{ margin: '20px' }}>
                            {topTen.map(place => {
                                return (
                                    <Place
                                        name={place.name}
                                        img={place.img}
                                        description={place.description}
                                        buttonText={place.buttonText}
                                        url={place.url}
                                        key={place.id}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {selectedTab === 'day-trips' && (
                        <div style={{ margin: '20px' }}>
                            {dayTrips.map(place => {
                                return (
                                    <SecondaryPlace
                                        name={place.name}
                                        img={place.img}
                                        description={place.description}
                                        buttonText={place.buttonText}
                                        url={place.url}
                                        key={place.id}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {selectedTab === 'overnight-trips' && (
                        <div style={{ margin: '20px' }}>
                            {overnightTrips.map(place => {
                                return (
                                    <SecondaryPlace
                                        name={place.name}
                                        img={place.img}
                                        description={place.description}
                                        buttonText={place.buttonText}
                                        url={place.url}
                                    />
                                );
                            })}
                        </div>
                    )}
                </FlexDiv>
            </FlexDiv>
        </Container>
    );
};

export default ThingsToDo;
