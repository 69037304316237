import styled from 'styled-components';
import { FlexDiv } from './FlexDiv';

const Card = styled(FlexDiv)`
    width: 100%;

    background-color: ${props =>
        props.type === 'primary'
            ? props.theme.color.blue
            : props.theme.color.lightBlue};
    border: ${props =>
        props.type === 'primary'
            ? 'none'
            : `2px solid ${props.theme.color.blue}`};
    border-style: ${props => (props.type === 'primary' ? 'none' : 'dashed')};
    margin: 20px;
    color: ${props =>
        props.type === 'primary' ? 'white' : props.theme.color.blue};
    border-radius: 5px;
    padding: 20px;
    max-width: 100%;
    display: flex;
    @media (max-width: 768px) {
        text-align: center;
    }
`;

const Title = styled.p`
    font-family: ${props => props.theme.font.bold};
    font-size: ${props => props.theme.fontSize.large};
`;

const DayCard = ({ children, title, type }) => {
    return (
        <Card column type={type}>
            <Title>{title}</Title>
            {children}
        </Card>
    );
};

export default DayCard;
