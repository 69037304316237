import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import Theme from './theme';
// import { AuthProvider } from './auth';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers';

//  create store
let store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Theme>
                <App />
            </Theme>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
