import styled from 'styled-components';
import { FlexDiv } from './FlexDiv';

const Container = styled(FlexDiv)`
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 500px;
    max-width: 100%;
    background-color: ${props => props.theme.color.background};
    color: ${props => props.theme.color.blue};
    justify-content: center;
    margin: 20px 0;
    padding: 15px;
    align-items: center;
    border-radius: 5px;
    box-shadow: ${props => props.theme.shadow.light};
    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        font-size: ${props => props.theme.fontSize.small};
        min-width: 300px;
    }
    &:hover {
        box-shadow: ${props => props.theme.shadow.dark};
    }
`;

const Title = styled.p`
    text-align: center;
    font-size: ${props => props.theme.fontSize.large};
    font-family: ${props => props.theme.font.bold};
    margin: 20px;
`;

const Description = styled.p`
    min-width: 200px;
    text-align: ${props => props.align};
    max-width: 50%;
    padding: 25px 30px;
    margin: 20px 0;
    @media (max-width: 768px) {
        text-align: center;
        padding: 10px;
        margin: 0;
    }
`;

const NormalImage = styled.img`
    flex-grow: 1;
    padding: 20px;
    display: block;
    max-width: 100%;
    max-height: 600px;
    width: auto;
    height: auto;
    overflow: hidden;
    @media (max-width: 768px) {
        padding: 5px;
        margin: 30px 0;
    }
`;

const A = styled.a`
    border-radius: 5px;
    background-color: ${props =>
        props.type === 'primary' ? props.theme.color.blue : 'white'};
    color: ${props =>
        props.type === 'primary' ? 'white' : props.theme.color.blue};
    box-shadow: ${props => props.theme.shadow.light};
    border: none;
    text-decoration: none;
    text-align: center;
    margin: auto;
    padding: 10px;
    &:hover {
        transition-timing-function: ease;
        transition: 0.2s;
        transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        cursor: pointer;
    }
`;

const Place = ({ name, description, img, buttonText, url }) => {
    return (
        <Container>
            <Title>{name}</Title>
            {buttonText !== '' && <A href={url}>{buttonText}</A>}
            <FlexDiv style={{ marginTop: '15px' }} smCol smCenter>
                <Description align="left">{description}</Description>
                <NormalImage src={img} alt={name} />
            </FlexDiv>
        </Container>
    );
};

export const SecondaryPlace = ({ name, description, img, buttonText, url }) => {
    return (
        <Container>
            <Title>{name}</Title>
            <FlexDiv center centerAlign smCol>
                <Description align="center">{description}</Description>
                {buttonText !== '' && <A href={url}>{buttonText}</A>}
            </FlexDiv>
            <FlexDiv center centerAlign>
                <NormalImage src={img} alt={name} />
            </FlexDiv>
        </Container>
    );
};

export default Place;
