export const topTen = [
    {
        id: 1,
        name: 'Schloss Nymphenburg',
        description:
            'Schloss Nymphenburg is a Baroque palace located in the Neuhausen-Nymphenburg neighborhood of Munich. It was commissioned by electoral couple Ferdinand Maria and Henriette Adelaide of Savoy in 1664 after the birth of their son, Maximillian II Emmanuel. Through the centuries, the Palace has remained a favorite residence for Bavarian nobility. In 1845 It served as the birthplace of the infamous King Ludwig II, who commissioned the Neuschwanstein Castle, and today it houses Franz, Duke of Bavaria.',
        img: 'https://upload.wikimedia.org/wikipedia/commons/8/80/Nymphenburger_Schloss_at_sunset.JPG',
        url: 'https://www.schloss-nymphenburg.de/englisch/tourist/index.htm',
        buttonText: 'Schedule your visit'
    },
    {
        id: 2,
        name: 'Pinakothek museums',
        description:
            'Munich is home to many fantastic museums including the Alte Pinakothek (housing artwork from the 14th-18th centuries), the Neue Pinakothek (19th century artwork) and the Pinakothek der Moderne (modern art). All three museums are conveniently located within walking distance of one another.',
        img: 'https://upload.wikimedia.org/wikipedia/commons/0/0d/Alte_Pinakothek_2009.jpg',
        url: 'https://www.pinakothek.de/en/visit',
        buttonText: 'Schedule a tour'
    },
    {
        id: 3,
        name: 'Olympiapark',
        description:
            'Munich’s Olympiapark was originally built for the 1972 summer Olympics. A notable experience offered by the park is the Olympia Tower observation deck which, at 291 meters high, offers a stunning and panoramic view of the city. No reservation is required!',
        img: 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Munich_Olympiapark_Public_Viewing_SCG-NED_%280-1%29.jpg',
        url: 'https://www.olympiapark.de/en/olympic-park/event-locations/olympic-tower/',
        buttonText: 'Click here for more info'
    },
    {
        id: 4,
        name: 'Viktualienmarkt',
        description:
            'The Viktualienmarkt is a historic open-air farmer’s market located in the heart of Munich. It hosts over 140 different vendors selling fresh produce, meat and various handmade goods.',
        img: 'https://upload.wikimedia.org/wikipedia/commons/5/5e/Munich_-_Viktualienmarkt.jpg',
        url: 'https://www.muenchen.de/int/en/shopping/markets/viktualienmarkt.html',
        buttonText: 'Click here for more info'
    },
    {
        id: 5,
        name: 'English Garden',
        description:
            'The English Garden is one of the largest urban public parks in the world and is a popular destination among locals for both recreation and relaxation. Some exciting sights within the park include the Japanese Teahouse, the Monopteros Apollo-Tempel and the Chinese Tower. On some days, you can even find surfers riding the waves of a stream passing through the Garden!',
        img: 'https://upload.wikimedia.org/wikipedia/commons/d/d7/Fall_foliage%2C_English_Garden%2C_Munich.jpg',
        url: 'https://www.muenchen.de/int/en/sights/parks/english-garden.html',
        buttonText: 'Click here for more info'
    },
    {
        id: 6,
        name: 'Asam Kirche',
        description:
            'The Asam Church was built by brothers Cosmas Damian Asam and Egid Quirin Asam between 1733 and 1746. It is notable for its narrow exterior, which measures only 8 meters across! The Church is located in the center of Munich making it within walking distance of many other attractions including the Viktualienmarkt and Pinakothek museums.',
        img: 'https://upload.wikimedia.org/wikipedia/commons/4/4e/St._Nepomuk_retusche.jpg',
        url: 'https://www.muenchen.de/int/en/sights/churches/asam-church.html',
        buttonText: 'Click here for more info'
    },
    {
        id: 7,
        name: 'BMW Museum',
        description:
            'Right next to BMW’s striking headquarter is the round, contemporary BMW museum which showcases (almost) all models BMW ever produced, including its limited edition and race cars. The museum also presents approaches to modern car construction, future propulsion technologies and various design ideas BMW is currently exploring',
        img: 'https://upload.wikimedia.org/wikipedia/commons/b/bd/Mus%C3%A9e_BMW_004.jpg',
        url: 'https://www.bmw-welt.com/en/locations/museum.html',
        buttonText: 'Click here for more info'
    },
    {
        id: 8,
        name: 'Hofbräuhaus',
        description:
            'Hofbräuhaus is one of the world’s most famous breweries and is well-known for its annual beer tent at Oktoberfest. The brewery’s tavern is located in central Munich and was founded over 400 years ago by the Duke of Bavaria, Wilhelm V.',
        img: 'https://upload.wikimedia.org/wikipedia/commons/6/60/Hofbrauhaus.JPG',
        url: 'https://www.hofbraeuhaus.de/en/welcome.html',
        buttonText: 'Click here for more info'
    },
    {
        id: 9,
        name: 'Old City Center',
        description:
            'The “Altstadt” is the historic city center and right next to the Hotel Bayerischer Hof. It includes many of Munich’s famous historic monuments like the main cathedral, the Marienplatz with the town hall, the Munich Residence, and the Opera house, all in walking distance. The area is also home to broad pedestrian areas with large & small shops, cafes, restaurants and theaters.',
        img: 'https://upload.wikimedia.org/wikipedia/commons/a/ae/Marienplatz_1900.jpg',
        url: 'https://en.wikipedia.org/wiki/Altstadt_(M%C3%BCnchen)',
        buttonText: 'Click here for more info'
    },
    {
        id: 10,
        name: 'Brandhorst Museum',
        description:
            'The Brandhorst Museum houses a stunning collection of contemporary artwork as part of the Pinakothek Museum family. It is well-known for its impressive and colorful facade that comprises over 36,000 ceramic louvres.',
        img: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Museum_Brandhorst_June_2014_01.JPG',
        url: 'https://www.museum-brandhorst.de/en/info-tickets/',
        buttonText: 'Book tickets'
    }
];

export const dayTrips = [
    {
        id: 1,
        name: 'Salzburg',
        description: '90m by car',
        img: 'https://upload.wikimedia.org/wikipedia/commons/9/91/Salzburg_%2848489551981%29.jpg',
        url: 'https://goo.gl/maps/YjBuSHVbRWmWrMHx9',
        buttonText: 'Open in Google Maps'
    },
    {
        id: 2,
        name: 'Schloss Neuschwanstein',
        description: '2h by car',
        img: 'https://img.traveltriangle.com/blog/wp-content/uploads/2018/05/acj-0805-neuschwanstein-Castle-10.jpg',
        url: 'https://goo.gl/maps/peQCyJGucWrRrWMJ7',
        buttonText: 'Open in Google Maps'
    },
    {
        id: 3,
        name: 'Garmisch-Partenkirchen',
        description: '90m by car',
        img: 'https://upload.wikimedia.org/wikipedia/commons/4/47/Garmisch-Partenkirchen.JPG',
        url: 'https://goo.gl/maps/icvrGByiVNFpnxiq8',
        buttonText: 'Open in Google Maps'
    },
    {
        id: 4,
        name: 'Rothenburg ob der Tauber',
        description: '2.5h by car',
        img: 'https://upload.wikimedia.org/wikipedia/commons/7/71/Rothenburg_BW_4.JPG',
        url: 'https://goo.gl/maps/XSzdMoCQcsisyhhbA',
        buttonText: 'Open in Google Maps'
    },
    {
        id: 5,
        name: 'Königssee and Berchtesgaden',
        description: '2h by car',
        img: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/07/17/b5/6d.jpg',
        url: 'https://goo.gl/maps/SPEQ9xgXeSCEgM2P9',
        buttonText: 'Open in Google Maps'
    }
];

export const overnightTrips = [
    {
        id: 1,
        name: 'Berlin',
        description: '4h by high-speed train',
        img: 'https://upload.wikimedia.org/wikipedia/commons/a/a6/Brandenburger_Tor_abends.jpg',
        url: '',
        buttonText: ''
    },
    {
        id: 2,
        name: 'Paris',
        description: '5.5h by high-speed train',
        img: 'https://upload.wikimedia.org/wikipedia/commons/4/4b/La_Tour_Eiffel_vue_de_la_Tour_Saint-Jacques%2C_Paris_ao%C3%BBt_2014_%282%29.jpg',
        url: '',
        buttonText: ''
    },
    {
        id: 3,
        name: 'Zurich',
        description: '3h by car',
        img: 'https://upload.wikimedia.org/wikipedia/commons/7/76/Z%C3%BCrich.jpg',
        url: '',
        buttonText: ''
    },
    {
        id: 4,
        name: 'Verona',
        description: '4.5h by car',
        img: 'https://upload.wikimedia.org/wikipedia/commons/4/43/Verona_-_ponte_pietra_at_sunset.jpg',
        url: '',
        buttonText: ''
    },
    {
        id: 5,
        name: 'Prague',
        description: '4h by car',
        img: 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Prague_from_Powder_Tower_01.jpg',
        url: '',
        buttonText: ''
    }
];
