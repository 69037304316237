import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 900px;
    min-width: 60vw;
    background-color: ${props => props.theme.color.transparentBlue};
    border-radius: 5px;
    margin: 15px 20px 50px 50px;
    padding: 50px;
    @media (max-width: 768px) {
        max-width: 80vw;
        min-width: 300px;
        margin: 20px;
        padding: 15px;
    }
`;
