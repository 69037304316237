import * as React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

export function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <div>
            <Link
                style={{
                    fontFamily: match && 'Larsseit-ExtraBold',
                    backgroundColor: match && '#CEE6FF'
                }}
                to={to}
                {...props}
            >
                {children}
            </Link>
        </div>
    );
}
