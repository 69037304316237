export const eventData = [
    {
        id: 1,
        day: 'Monday, October 3rd',
        type: 'primary',
        events: [
            {
                id: '1-a',
                title: '*Optional Oktoberfest outing',
                time: '5PM',
                gcal: 'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20221003T150000Z%2F20221003T200000Z&location=Oktoberfest&text=%2AOptional%20Oktoberfest%20Outling',
                outlook:
                    'https://outlook.live.com/calendar/0/deeplink/compose?enddt=2022-10-03T20%3A00%3A00%2B00%3A00&location=Oktoberfest&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-10-03T15%3A00%3A00%2B00%3A00&subject=%2AOptional%20Oktoberfest%20Outling'
            }
        ]
    },
    {
        id: 2,
        day: 'Tuesday & Wednesday, October 4th & 5th',
        type: 'secondary',
        events: [{ id: '2-b', title: '*Individual activity days' }]
    },
    {
        id: 3,
        day: 'Day 1: Thursday, October 6th',
        type: 'primary',
        events: [
            {
                id: '3-c',
                title: 'Dinner @ Esszimmer - BMW Welt',
                url: 'https://www.bmw-welt.com/en/experience/popups/gastronomie_bmw_welt/esszimmer.html',
                time: '6:30PM',
                attire: 'Cocktail',
                gcal: 'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20221006T163000Z%2F20221006T203000Z&location=&text=Dinner',
                outlook:
                    'https://outlook.live.com/calendar/0/deeplink/compose?enddt=2022-10-06T20%3A30%3A00%2B00%3A00&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-10-06T16%3A30%3A00%2B00%3A00&subject=Dinner'
            }
        ]
    },
    {
        id: 4,
        day: 'Day 2: Friday, October 7th',
        type: 'primary',
        events: [
            {
                id: '4-d',
                title: 'Board Meeting of Directors (incl. breakfast) @ Hotel Bayerischer Hof',
                time: '9:30AM',
                gcal: 'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20221007T073000Z%2F20221007T093000Z&location=&text=Board%20Meeting%20incl.%20Breakfast',
                outlook:
                    'https://outlook.live.com/calendar/0/deeplink/compose?enddt=2022-10-07T09%3A30%3A00%2B00%3A00&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-10-07T07%3A30%3A00%2B00%3A00&subject=Board%20Meeting%20incl.%20Breakfast'
            },
            {
                id: '4-e',
                title: 'Tour of Brandhorst Museum @ Pinakothek, Munich',
                url: 'https://www.museum-brandhorst.de/en/',
                time: 'TBD',
                location: '',
                gcal: 'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20221007T120000Z%2F20221007T140000Z&location=&text=Optional%20Activity',
                outlook:
                    'https://outlook.live.com/calendar/0/deeplink/compose?enddt=2022-10-07T14%3A00%3A00%2B00%3A00&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-10-07T12%3A00%3A00%2B00%3A00&subject=Optional%20Activity'
            },
            {
                id: '4-g',
                title: 'Tour of the BMW Factory / Munich Brewery',
                url: '',
                time: 'TBD',
                gcal: '',
                outlook: ''
            },
            {
                id: '4-h',
                title: 'Dinner @ Restaurant Tantris',
                url: 'https://tantris.de/en/',
                time: '6:30PM',
                attire: 'Cocktail',
                gcal: 'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20221007T163000Z%2F20221007T203000Z&location=&text=Dinner',
                outlook:
                    'https://outlook.live.com/calendar/0/deeplink/compose?enddt=2022-10-07T20%3A30%3A00%2B00%3A00&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-10-07T16%3A30%3A00%2B00%3A00&subject=Dinner'
            }
        ]
    },
    {
        id: 5,
        day: 'Day 3: Saturday, October 8th',
        type: 'primary',
        events: [
            {
                id: '5-g',
                title: 'Riesling Lunch @ Werneckhof',
                url: 'https://werneckhof-schelling.de/en',
                time: '12PM (noon)',
                attire: 'Casual',
                gcal: 'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20221008T100000Z%2F20221008T123000Z&location=&text=Lunch',
                outlook:
                    'https://outlook.live.com/calendar/0/deeplink/compose?enddt=2022-10-08T12%3A30%3A00%2B00%3A00&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-10-08T10%3A00%3A00%2B00%3A00&subject=Lunch'
            },
            {
                id: '5-h',
                title: 'Dinner @ Schloss Schleissheim',
                url: 'https://www.schloesser-schleissheim.de/englisch/schleissheim/index.htm',
                time: '6PM',
                attire: 'Attire: festive, black tie optional',
                gcal: 'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20221008T160000Z%2F20221008T210000Z&location=&text=Dinner',
                outlook:
                    'https://outlook.live.com/calendar/0/deeplink/compose?enddt=2022-10-08T21%3A00%3A00%2B00%3A00&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-10-08T16%3A00%3A00%2B00%3A00&subject=Dinner'
            }
        ]
    }
];
