import styled from 'styled-components';

import { FlexDiv } from './FlexDiv';

const StyledImg = styled.img`
    border-radius: 50%;
    width: 75px;
    height: 75px;
    object-fit: cover;
    margin: 20px;
`;

const Date = styled.p`
    font-size: ${props => props.theme.fontSize.small};
    color: grey;
    padding-left: 20px;
`;

const ProfileImage = ({ img, date }) => {
    return (
        <FlexDiv
            column
            style={{ paddingBottom: '4px', justifyContent: 'left' }}
        >
            <StyledImg
                src={img}
                alt="profile-picture"
                style={{ marginBottom: '4px' }}
            />
            {date && <Date>{date}</Date>}
        </FlexDiv>
    );
};

export default ProfileImage;
