import { SELECT_PLACE_TAB } from '../constants/actionTypes';

const selectPlaceTabReducer = (state = 'top-ten', action) => {
    switch (action.type) {
        case SELECT_PLACE_TAB:
            return (state = action.payload);
        default:
            return state;
    }
};

export default selectPlaceTabReducer;
