import { TOGGLE_CAL_POPUP } from '../constants/actionTypes';

const toggleCalendarPopupReducer = (state = '', action) => {
    switch (action.type) {
        case TOGGLE_CAL_POPUP:
            return (state = action.payload);
        default:
            return state;
    }
};

export default toggleCalendarPopupReducer;
