import styled from 'styled-components';
import Button from '../components/Button';
import { FlexDiv } from './FlexDiv';

const EventContainer = styled(FlexDiv)`
    margin: 10px 0;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: ${props => props.theme.fontSize.small};
    }
`;

const Date = styled.p`
    margin-right: 50px;
    width: 90px;
    @media (max-width: 768px) {
        margin-right: 0;
    }
`;

const EventTitle = styled.p`
    text-align: left;
`;

const AttireText = styled.p`
    font-size: 12px;
`;

const Event = ({ date, name, url, attire }) => {
    return (
        <EventContainer>
            <Date>{date}</Date>
            <FlexDiv column>
                <EventTitle>{name}</EventTitle>
                {attire && <AttireText>Attire: {attire}</AttireText>}
            </FlexDiv>
            <div style={{ flexGrow: '1' }}></div>
            {url && (
                <Button
                    onClick={() => window.open(url, '_blank').focus()}
                    primary
                    text="Website"
                />
            )}
        </EventContainer>
    );
};

export default Event;
