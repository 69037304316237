import styled from 'styled-components';
import cross from '../assets/icons/cross.png';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCalendarPopup } from '../actions';
import { eventData } from '../data/eventData';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    background-color: ${props => props.theme.color.darkTransparentBlue};
`;

const Background = styled.div`
    color: ${props => props.theme.color.blue};
    background-color: ${props => props.theme.color.background};
    box-shadow: ${props => props.theme.shadow.dark};
    width: 550px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: relative;
    text-align: center;
    padding: 25px;
    font-size: ${props => props.theme.fontSize.medium};
    @media (max-width: 768px) {
        width: 350px;
    }
`;

const Icon = styled.img`
    width: 25px;
    height: 25px;
    overflow: hidden;
    object-fit: contain;
    margin: 0;
    position: absolute;
    top: 15px;
    right: 15px;
    &:hover {
        transition-timing-function: ease;
        transition: 0.2s;
        transform: scale(1.2, 1.2);
        -webkit-transform: scale(1.2, 1.2);
        -moz-transform: scale(1.2, 1.2);
        cursor: pointer;
    }
`;

const A = styled.a`
    padding-bottom: 20px;
    border-radius: 5px;
    background-color: ${props =>
        props.type === 'primary' ? props.theme.color.blue : 'white'};
    color: ${props =>
        props.type === 'primary' ? 'white' : props.theme.color.blue};
    box-shadow: ${props => props.theme.shadow.light};
    border: none;
    text-decoration: none;
    display: flex;
    text-align: center;
    padding: 15px;
    margin: 15px;
    &:hover {
        transition-timing-function: ease;
        transition: 0.2s;
        transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        cursor: pointer;
    }
`;

const AddToCalendarPopup = () => {
    const dispatch = useDispatch();
    const selectedEvent = useSelector(state => state.toggleCalendarPopup);
    var gcalLink;
    var outlookLink;
    if (selectedEvent !== '') {
        const dayId = selectedEvent.split('-')[0];
        const day = eventData.filter(d => d.id === +dayId);
        const event = day[0].events.filter(e => e.id === selectedEvent);
        gcalLink = event[0].gcal;
        outlookLink = event[0].outlook;
    }
    return (
        <Container>
            <Background>
                <Icon
                    src={cross}
                    alt="cross"
                    onClick={() => {
                        dispatch(toggleCalendarPopup(''));
                    }}
                />
                <A href={gcalLink}>Add to Google Calendar</A>
                <A href={outlookLink}>Add to Outlook</A>
            </Background>
        </Container>
    );
};

export default AddToCalendarPopup;
