// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import 'firebase/auth';

// Your web app's Firebase configuration
const app = initializeApp({
    apiKey: 'AIzaSyBJitXnVsAkzWWiGUyvtxonY-IGTlJ7OyU',
    authDomain: 'bacchus-977af.firebaseapp.com',
    projectId: 'bacchus-977af',
    storageBucket: 'bacchus-977af.appspot.com',
    messagingSenderId: '598802366480',
    appId: '1:598802366480:web:1361946b42f7504c4aca95'
});

export default app;
