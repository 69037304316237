import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { toggleHotelPopup } from '../actions';
import { FlexDiv } from '../components/FlexDiv';
import { Container } from '../components/Container';
import ProfileImage from '../components/ProfileImage';
import SpeechBubble from '../components/SpeechBubble';
import LargeButton from '../components/LargeButton';
import Header from '../components/Header';
import HotelInfoPopup from '../components/HotelInfoPopup';

import robert from '../assets/robert.png';

import bh1 from '../assets/bayerischer-hof/bayrischer-hof-1.jpg';
import bh2 from '../assets/bayerischer-hof/bayrischer-hof-2.jpg';
import bh3 from '../assets/bayerischer-hof/bayrischer-hof-3.jpg';
import bh5 from '../assets/bayerischer-hof/bayrischer-hof-5.jpg';
import bh6 from '../assets/bayerischer-hof/bayrischer-hof-6.jpg';

const WideImg = styled.img`
    flex-grow: 1;
    padding: 20px;
    display: block;
    max-height: 500px;
    width: auto;
`;

const NormalImage = styled.img`
    flex-grow: 1;
    padding: 20px;
    display: block;
    max-width: 100%;
    max-height: 400px;
    width: auto;
    height: auto;
    overflow: hidden;
    @media (max-width: 768px) {
        padding: 5px;
        margin: 30px 0;
    }
`;

const Hotel = () => {
    const dispatch = useDispatch();
    const toggleHotelPopupState = useSelector(state => state.toggleHotelPopup);

    return (
        <FlexDiv>
            {toggleHotelPopupState && <HotelInfoPopup />}
            <Container>
                <div style={{ margin: '10px 0 0 20px' }}>
                    <Header text="Hotel Bayerischer Hof" />
                </div>
                <FlexDiv column speechBubble>
                    <ProfileImage img={robert} />
                    <SpeechBubble size="sm" type="top-left">
                        <p style={{ marginBottom: '20px' }}>
                            Special rates have been arranged for our group,
                            based on availability. Place reference the Society
                            of Bacchus America when placing your reservation.
                        </p>
                        <FlexDiv center>
                            <LargeButton
                                text="Contact hotel"
                                type="primary"
                                onClick={() => dispatch(toggleHotelPopup())}
                            />
                        </FlexDiv>
                    </SpeechBubble>
                </FlexDiv>
                <FlexDiv center>
                    <WideImg src={bh2} />
                </FlexDiv>
                <FlexDiv center></FlexDiv>
                <FlexDiv center smCol>
                    <NormalImage src={bh5} />
                    <NormalImage src={bh6} />
                </FlexDiv>
                <FlexDiv smCol>
                    <NormalImage src={bh1} />
                    <NormalImage src={bh3} />
                </FlexDiv>
            </Container>
        </FlexDiv>
    );
};

export default Hotel;
