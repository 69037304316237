import { useDispatch, useSelector } from 'react-redux';
import { toggleCalendarPopup } from '../actions';

import { FlexDiv } from '../components/FlexDiv';
import { Container } from '../components/Container';
import ProfileImage from '../components/ProfileImage';
import SpeechBubble from '../components/SpeechBubble';
import DayCard from '../components/DayCard';
import Event from '../components/Event';
import { eventData } from '../data/eventData';
import robert from '../assets/robert.png';
import AddToCalendarPopup from '../components/AddToCalendarPopup';

const Schedule = () => {
    const dispatch = useDispatch();
    const calPopupState = useSelector(state => state.toggleCalendarPopup);
    return (
        <FlexDiv>
            {calPopupState !== '' && <AddToCalendarPopup />}
            <Container>
                <FlexDiv center column speechBubble>
                    <ProfileImage img={robert} />
                    <SpeechBubble size="sm" type="top-left">
                        <p>Here is the schedule for the long weekend!</p>
                    </SpeechBubble>
                </FlexDiv>
                <FlexDiv center column centerAlign>
                    {eventData.map(day => {
                        return (
                            <DayCard title={day.day} type={day.type}>
                                {day.events.map(event => {
                                    return (
                                        <Event
                                            date={event.time}
                                            name={event.title}
                                            attire={event.attire}
                                            url={event.url}
                                        />
                                    );
                                })}
                            </DayCard>
                        );
                    })}
                </FlexDiv>
            </Container>
        </FlexDiv>
    );
};

export default Schedule;
