import welcome from '../assets/icons/welcome.png';
import schedule from '../assets/icons/schedule.png';
import things from '../assets/icons/thingstodo.png';
import hotel from '../assets/icons/hotel.png';
import payment from '../assets/icons/payment.png';

export const navData = [
    { title: 'Welcome', slug: '/welcome', img: welcome, id: '1' },
    { title: 'Schedule', slug: '/schedule', img: schedule, id: '2' },
    { title: 'Things to do', slug: '/thingstodo', img: things, id: '3' },
    { title: 'Hotel', slug: '/hotel', img: hotel, id: '4' },
    { title: 'Payment', slug: '/payment', img: payment, id: '5' }
];
