import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import { FlexDiv } from '../components/FlexDiv';
import Header from '../components/Header';
import Form from '../components/Form';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../auth';

const Background = styled.div`
    margin: 50px 0 0 0;
    max-width: 700px;
    min-width: 60vw;
    height: 300px;
    border-radius: 5px;
    background-color: ${props => props.theme.color.transparentBlue};
    padding: 30px;
    display: flex;
    flex-direction: column;
`;

function Login() {
    const navigate = useNavigate();
    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token');

        if (authToken) {
            navigate('/welcome');
        }
    }, [navigate]);

    const handleLogin = password => {
        const authentication = getAuth();
        signInWithEmailAndPassword(
            authentication,
            'bacchus2022munich@gmail.com',
            password
        )
            .then(response => {
                console.log(response);
                navigate('/welcome');
                sessionStorage.setItem(
                    'Auth Token',
                    response._tokenResponse.refreshToken
                );
            })
            .catch(error => {
                console.log(error.message);
                window.alert(error.message);
            });
    };

    const { currentUser } = useContext(AuthContext) || {};

    if (currentUser) {
        return navigate('/welcome');
    }
    return (
        <div>
            <div className="hero-image" />
            <FlexDiv center>
                <Background>
                    <Header text="Bacchus Society of America" />
                    <Header text="Munich 2022" />
                    <FlexDiv center centerAlign>
                        <Form onClick={handleLogin} />
                    </FlexDiv>
                </Background>
            </FlexDiv>
        </div>
    );
}

export default Login;
