import React from 'react';
import { Navigate } from 'react-router-dom';
import Nav, { MobileNav } from '../components/Nav';
import { FlexDiv } from '../components/FlexDiv';
import { useDispatch } from 'react-redux';
import { toggleHamburger } from '../actions';

function PrivateRoute({ children, path }) {
    const dispatch = useDispatch();
    const currentUser = sessionStorage.getItem('Auth Token');
    return currentUser !== null ? (
        <div>
            <div className="hero-image" />
            <FlexDiv smCol>
                <Nav />
                <MobileNav onClick={() => dispatch(toggleHamburger())} />
                <FlexDiv smCol smCenter>
                    {children}
                </FlexDiv>
            </FlexDiv>
        </div>
    ) : (
        <Navigate to="/login" />
    );
}

export default PrivateRoute;
