import { TOGGLE_HAMBURGER } from '../constants/actionTypes';

const toggleHamburgerReducer = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_HAMBURGER:
            return (state = !state);
        default:
            return state;
    }
};

export default toggleHamburgerReducer;
