import { combineReducers } from 'redux';
import toggleHamburgerReducer from './toggleHamburger';
import toggleHotelPopupReducer from './toggleHotelPopup';
import toggleCalendarPopupReducer from './toggleCalendarPopup';
import selectPlaceTabReducer from './selectPlaceTab';

const rootReducer = combineReducers({
    toggleHamburgerReducer: toggleHamburgerReducer,
    toggleHotelPopup: toggleHotelPopupReducer,
    toggleCalendarPopup: toggleCalendarPopupReducer,
    selectPlaceTab: selectPlaceTabReducer
});

export default rootReducer;
