import { FlexDiv } from '../components/FlexDiv';
import { Container } from '../components/Container';
import SpeechBubble from '../components/SpeechBubble';
import ProfileImage from '../components/ProfileImage';
import robert from '../assets/robert.png';
import styled from 'styled-components';
import { welcomeData } from '../data/welcomeData';

const StyledP = styled.p`
    margin: 15px 0;
`;

const Welcome = () => {
    console.log('welcome');
    return (
        <Container>
            <>
                {welcomeData.map(m => {
                    return (
                        <FlexDiv center column speechBubble>
                            <div>
                                <ProfileImage img={robert} date={m.date} />
                            </div>
                            <SpeechBubble type="top-left">
                                {m.paragraphs.map(p => {
                                    return <StyledP>{p}</StyledP>;
                                })}
                            </SpeechBubble>
                        </FlexDiv>
                    );
                })}
            </>
        </Container>
    );
};

export default Welcome;
