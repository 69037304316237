import styled from 'styled-components';

const StyledButton = styled.button`
    border-radius: 5px;
    background-color: ${props =>
        props.type === 'primary' ? props.theme.color.blue : 'white'};
    color: ${props =>
        props.type === 'primary' ? 'white' : props.theme.color.blue};
    box-shadow: ${props => props.theme.shadow.light};
    border: none;
    max-width: 180px;
    padding: 10px 15px;
    &:hover {
        transition-timing-function: ease;
        transition: 0.2s;
        transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        cursor: pointer;
    }
`;

const LargeButton = ({ text, onClick, type }) => {
    return (
        <StyledButton type={type} onClick={onClick}>
            {text}
        </StyledButton>
    );
};

export default LargeButton;
