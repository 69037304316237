import {
    TOGGLE_HAMBURGER,
    TOGGLE_HOTEL_POPUP,
    TOGGLE_CAL_POPUP,
    SELECT_PLACE_TAB
} from '../constants/actionTypes';

export const toggleHamburger = () => {
    return {
        type: TOGGLE_HAMBURGER
    };
};

export const toggleHotelPopup = () => {
    return {
        type: TOGGLE_HOTEL_POPUP
    };
};

export const toggleCalendarPopup = eventId => {
    return {
        type: TOGGLE_CAL_POPUP,
        payload: eventId
    };
};

export const selectPlaceTab = tab => {
    return {
        type: SELECT_PLACE_TAB,
        payload: tab
    };
};
