import styled from 'styled-components';

export const FlexDiv = styled.div`
    display: flex;
    flex-direction: ${props => (props.column ? 'column' : 'row')};
    height: ${props => (props.fullHeight ? '100%' : 'auto')};
    justify-content: ${props => (props.center ? 'center' : '')};
    align-items: ${props => (props.centerAlign ? 'center' : '')};
    font-family: ${props => props.theme.font.medium};
    @media (max-width: 768px) {
        flex-direction: ${props => props.smCol && 'column'};
        flex-direction: ${props => props.smColRev && 'column-reverse'};
        justify-content: ${props => props.smCenter && 'center'};
        align-items: ${props => props.smCenter && 'center'};
        padding: 0;
    }
    /* speech bubble */
    background-color: ${props => props.speechBubble && 'white'};
    border-radius: ${props => props.speechBubble && '5px'};
    padding: ${props => props.speechBubble && '20px'};
`;
