import { FlexDiv } from '../components/FlexDiv';
import { Container } from '../components/Container';
import Header from '../components/Header';
import styled from 'styled-components';

const CostText = styled.p`
    font-family: ${props => props.theme.font.bold};
    font-size: 24px;
    color: ${props => props.theme.color.blue};
    margin: 15px 0;
`;
const P = styled.p`
    font-family: ${props => props.theme.font.bold};
    color: ${props => props.theme.color.blue};
    margin-top: 24px;
    margin-bottom: 48px;
    font-size: 32px;
`;

const Payment = () => {
    return (
        <Container>
            <FlexDiv column center fullHeight style={{ padding: '20px' }}>
                <P>
                    Kindly return the form included in your invitation and a
                    check by <u>August 21, 2022</u>
                </P>
                <CostText>Each Lunch $300/person</CostText>
                <CostText>Each Dinner $400/person</CostText>
            </FlexDiv>
        </Container>
    );
};

export default Payment;
