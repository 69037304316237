export const welcomeData = [
    {
        id: 2,
        date: 'August 2022',
        paragraphs: [
            'Hello everyone,',
            'We hope that you are having an enjoyable summer wherever you are at the moment! Carmen and I are very much looking forward to seeing you in Munich soon and - with less than two months to go - wanted to share a quick update',
            'Invitations',
            'The printed invitations were sent out from Germany at the end of July and hopefully, you all have received them in your mailbox by now. We did send invitations to the Lifetime Achievement Award members at that time as well - so if you are the designated contact for one of the LTAA’s, it might be a good opportunity to contact them. Since all restaurants will approach their maximum capacity with our Bacchus group, we would appreciate if you would kindly return the reply card by August 21 so that we can adapt and work with the restaurants accordingly',
            'Lunch',
            'Sticking with the good tradition, I will share the wine themes on the evening of the respective dinners. However, I do want to make an exception for the lunch on Saturday - it will be all about Riesling! I have been approached by quite a few of you (some with concern and some with excitement!) asking if I plan to serve a ton of Riesling’s on that weekend and I want everyone to know what they are signing up for in advance. I am particularly proud of this specific lunch line-up as it covers my favorite producers, top German regions, different Riesling styles, various “Praedikatslevel” and that all in a very condensed midday event. I can assure you that it will be interesting for both the Riesling aficionado and the Riesling novice – having said that, this particular event unfortunately comes with a significant capacity limitation',
            'Hotel',
            'Just a reminder that Hotel Bayerischer Hof München is our “Bacchus headquarters” for the weekend where all transportation will depart from/arrive to. Any changes to your hotel reservation must be made 14 days prior to arrival to avoid cancellation fees, so please keep that in mind during the coming weeks',
            'Oktoberfest',
            'We will have a separate email chain for the Oktoberfest visit on October 3rd (Monday). I expect a great turnout and have confirmed attendance so far from the following families: Kaplan, Feibleman, Maier, Wong, Lewis, Caine, Weintraub, Tuft, Lutgert, Hobson, Davis, Brincko, Kaufman, Seeger, Bronfman, Kovac, Abramson. If you are not listed here and plan to attend (or if you are incorrectly listed), please let us know as soon as possible',
            'Support',
            'If you need any additional advice or help either before or during your stay, Mike Roleff and his excellent team at Welcome Munich Destination Management are happy to help. Mike’s email is m.roleff@welcomemuc.com and his cell phone number is +49/171/651-6151',
            'Liebe Gruesse und bis bald in München',
            'Carmen and Robert'
        ]
    },
    {
        id: 1,
        date: 'January 2022',
        paragraphs: [
            'Carmen and I are thrilled to host you this coming October for the Bacchanalia 2022 weekend in Munich, Germany!',
            'While we call Santa Monica our home these days, Munich has always been a special place for us - we lived there for almost 20 years, worked & studied there and both our kids graduated high school there. We plan to show you a very special side of the city and hopefully, you will love it as much as we do.',
            'Our official events will take place between Thursday, October 6th and Saturday, October 8th. We do hope, though, that many of you will be able to join us early for Oktoberfest on Monday, October 3rd; this is the last day of Oktoberfest and we will have some tables reserved for our group in the evening!',
            'We recommend that you book your hotel rooms as soon as possible. We have selected the Hotel Bayerischer Hof, a local five-star hotel right in the city center, as our “Bacchus headquarters”. It is within walking distance of many sightseeing locations, has a fantastic spa and hosts a team that is super excited to make you feel at home during your stay.',
            'We have arranged special rates for our group and you can find more information under the “Hotel” tab at this website.',
            'We will also share more details on planned events etc. in the coming months via the website, so please be on the lookout for further notifications. ',
            'Herzlichste Grüsse',
            'Robert & Carmen'
        ]
    }
];
