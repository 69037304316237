import styled from 'styled-components';
import cross from '../assets/icons/cross.png';
import { useDispatch } from 'react-redux';
import { toggleHotelPopup } from '../actions';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    background-color: ${props => props.theme.color.darkTransparentBlue};
`;

const Background = styled.div`
    color: ${props => props.theme.color.blue};
    background-color: ${props => props.theme.color.background};
    box-shadow: ${props => props.theme.shadow.dark};
    width: 550px;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: relative;
    text-align: center;
    padding: 25px;
    font-size: ${props => props.theme.fontSize.medium};
    @media (max-width: 768px) {
        width: 350px;
    }
`;

const Icon = styled.img`
    width: 25px;
    height: 25px;
    overflow: hidden;
    object-fit: contain;
    margin: 0;
    position: absolute;
    top: 15px;
    right: 15px;
    &:hover {
        transition-timing-function: ease;
        transition: 0.2s;
        transform: scale(1.2, 1.2);
        -webkit-transform: scale(1.2, 1.2);
        -moz-transform: scale(1.2, 1.2);
        cursor: pointer;
    }
`;

const A = styled.a`
    padding-bottom: 20px;
    border-radius: 5px;
    background-color: ${props =>
        props.type === 'primary' ? props.theme.color.blue : 'white'};
    color: ${props =>
        props.type === 'primary' ? 'white' : props.theme.color.blue};
    box-shadow: ${props => props.theme.shadow.light};
    border: none;
    text-decoration: none;
    display: flex;
    text-align: center;
    padding: 15px;
    margin: 15px 15px 25px;
    &:hover {
        transition-timing-function: ease;
        transition: 0.2s;
        transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        cursor: pointer;
    }
`;

const HotelInfoPopup = () => {
    const dispatch = useDispatch();
    return (
        <Container>
            <Background>
                <Icon
                    src={cross}
                    alt="cross"
                    onClick={() => {
                        dispatch(toggleHotelPopup());
                    }}
                />
                <A
                    type="primary"
                    href="https://www.bayerischerhof.de/en/index.html"
                >
                    Website
                </A>
                {/* <p>
                    Special rates have been arranged for our group, based on
                    availability. Please reference the Society of Bacchus
                    America when placing your reservation
                </p> */}
                <A type="primary" href="mailto:groups@bayerischerhof.de">
                    Email groups@bayerischerhof.de
                </A>
                <p style={{ fontSize: '14px' }}>
                    Please reference the Society of Bacchus America when placing
                    your reservation.
                </p>
                <p style={{ marginTop: '30px' }}>Hotel Bayerischer Hof</p>
                <p>Sören Huber</p>
                <p>Sales Ambassador for the owner</p>
                <p>Gebrüder Volkhardt KG</p>
                <p>Promenadenplatz 2-6</p>
                <p>D-80333 München</p>
                <p>Phone: +49 89 2120 900</p>
            </Background>
        </Container>
    );
};

export default HotelInfoPopup;
