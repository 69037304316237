import React, { useState } from 'react';
import styled from 'styled-components';

const Background = styled.div`
    height: 50px;
    min-width: 300px;
    display: flex;
    background-color: white;
`;

const Input = styled.input`
    margin: 5px;
    flex-grow: 1;
    background-color: #e3efff;
    border: none;
    border-radius: 5px;
    padding: 20px;
`;

const Button = styled.button`
    margin: 5px;
    flex-grow: 1;
    border-radius: 5px;
    background-color: ${props =>
        props.type === 'primary' ? props.theme.color.blue : 'white'};
    color: ${props =>
        props.type === 'primary' ? 'white' : props.theme.color.blue};
    box-shadow: ${props => props.theme.shadow.light};
    border: none;
    &:hover {
        transition-timing-function: ease;
        transition: 0.2s;
        transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
    }
`;

function Form(props) {
    const [input, setInput] = useState('');
    const handleClick = () => {
        return props.onClick(input);
    };

    return (
        <Background>
            <Input
                placeholder="Enter password"
                value={input}
                onChange={e => setInput(e.target.value)}
            ></Input>
            <Button type="primary" onClick={handleClick}>
                Enter
            </Button>
        </Background>
    );
}

export default Form;
