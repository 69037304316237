import { TOGGLE_HOTEL_POPUP } from '../constants/actionTypes';

const toggleHotelPopupReducer = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_HOTEL_POPUP:
            return (state = !state);
        default:
            return state;
    }
};

export default toggleHotelPopupReducer;
