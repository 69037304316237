import styled from 'styled-components';

const Bubble = styled.div`
    background-color: ${props => props.theme.color.lightBlue};
    border-radius: ${props => props.type === 'right' && '20px 20px 3px 20px'};
    border-radius: ${props => props.type === 'left' && '20px 20px 20px 3px'};
    border-radius: ${props =>
        props.type === 'top-left' && '3px 20px 20px 20px'};
    border-radius: ${props =>
        props.type === 'top-right' && '20px 3px 20px 20px'};
    font-family: ${props => props.theme.font.boldItalic};
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.color.blue};
    padding: ${props => (props.size === 'sm' ? '20px 40px' : '25px 70px')};
    max-width: ${props => (props.size === 'sm' ? '500px' : '800px')};
    margin: 0 20px 20px;
    @media (max-width: 768px) {
        font-size: ${props => props.theme.fontSize.small};
        padding: 10px 25px;
    }
`;

const SpeechBubble = ({ children, type, size }) => {
    return (
        <Bubble size={size} type={type}>
            {children}
        </Bubble>
    );
};

export default SpeechBubble;
