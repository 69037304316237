import styled from 'styled-components';

const Text = styled.p`
    font-family: ${props => props.theme.font.boldItalic};
    font-size: ${props => props.theme.fontSize.huge};
    color: ${props => props.theme.color.blue};
    margin: 15px 0;
    @media (max-width: 768px) {
        font-size: ${props => props.theme.fontSize.large};
    }
`;

const Header = ({ text }) => {
    return <Text>{text}</Text>;
};

export default Header;
