import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { CustomLink } from '../routes/CustomLink';
import { navData } from '../data/navData';
import hamburger from '../assets/icons/hamburger.png';
import { useSelector, useDispatch } from 'react-redux';
import { toggleHamburger } from '../actions';

const StyledNav = styled.nav`
    background-color: ${props => props.theme.color.background};
    height: 325px;
    width: 200px;
    border-radius: 5px;
    box-shadow: ${props => props.theme.shadow.light};
    font-family: ${props => props.theme.font.medium};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px 0 0 15px;
    z-index: 100;
    @media (max-width: 768px) {
        position: ${props => props.hamburgedToggle === true && 'fixed'};
        ${props => props.hamburgedToggle === false && 'display: none'};
    }
`;

const StyledList = styled.ul`
    list-style-type: none;
`;

const StyledLi = styled.li`
    display: flex;
    flex-direction: column;
`;

const StyledLink = styled(CustomLink)`
    color: ${props => props.theme.color.blue};
    text-decoration: none;
    font-size: ${props => props.theme.fontSize.small};
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 10px auto;
    padding: 10px 0;
    width: ${props => (props.mobile ? '80vw' : '80%')};
    &:hover {
        background-color: ${props => props.theme.color.lightBlue};
    }
`;

const Icon = styled.img`
    width: 15px;
    height: 15px;
    overflow: hidden;
    object-fit: contain;
    margin: 0 15px;
`;

const Nav = () => {
    const dispatch = useDispatch();
    const hamburgerStatus = useSelector(state => state.toggleHamburgerReducer);
    return (
        <div>
            <StyledNav hamburgedToggle={hamburgerStatus}>
                <StyledList>
                    {navData.map(n => {
                        return (
                            <StyledLi
                                onClick={() => dispatch(toggleHamburger())}
                                key={n.id}
                            >
                                <StyledLink to={n.slug}>
                                    <Icon src={n.img} alt={n.title} />
                                    {n.title}
                                </StyledLink>
                            </StyledLi>
                        );
                    })}
                </StyledList>
            </StyledNav>
            <Outlet />
        </div>
    );
};

export default Nav;

const Container = styled.div`
    width: 100vw;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
        display: none;
    }
`;

const HamburgerIcon = styled.img`
    width: 35px;
    height: 35px;
    overflow: hidden;
    object-fit: contain;
    margin: 0 15%;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    align-items: center;
`;

export const MobileNav = ({ onClick }) => {
    const location = useLocation();
    console.log(location.pathname);
    const hamburgerStatus = useSelector(state => state.toggleHamburgerReducer);

    return (
        <Container onClick={onClick}>
            {navData.map(n => {
                return (
                    <>
                        {location.pathname === n.slug &&
                            hamburgerStatus !== true && (
                                <StyledLink mobile to={n.slug}>
                                    <HamburgerIcon
                                        src={hamburger}
                                        alt="hamburger-menu"
                                    />
                                    <StyledDiv>
                                        <Icon src={n.img} alt={n.title} />
                                        {n.title}
                                    </StyledDiv>
                                </StyledLink>
                            )}
                    </>
                );
            })}
        </Container>
    );
};
