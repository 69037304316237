import styled from 'styled-components';

const StyledButton = styled.button`
    border-radius: 5px;
    background-color: ${props =>
        props.type === 'primary' ? props.theme.color.blue : 'white'};
    color: ${props =>
        props.type === 'primary' ? 'white' : props.theme.color.blue};
    box-shadow: ${props => props.theme.shadow.light};
    border: none;
    margin: 15px;
    padding: 5px 10px;
    &:hover {
        transition-timing-function: ease;
        transition: 0.2s;
        transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        cursor: pointer;
    }
    @media (max-width: 768px) {
        font-size: ${props => props.theme.fontSize.small};
        padding: 5px;
    }
`;

const Button = ({ text, onClick, type }) => {
    return (
        <StyledButton type={type} onClick={onClick}>
            {text}
        </StyledButton>
    );
};

export default Button;
