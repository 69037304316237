import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './routes/PrivateRoute';

import LoginPage from './pages/Login';
import Welcome from './pages/Welcome';
import Schedule from './pages/Schedule';
import Hotel from './pages/Hotel';
import ThingsToDo from './pages/ThingsToDo';
import Payment from './pages/Payment';

const App = () => {
    return (
        <>
            <Routes>
                <Route path="/login" element={<LoginPage />} />

                <Route
                    path="/welcome"
                    element={
                        <PrivateRoute path="welcome">
                            <Welcome />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/schedule"
                    element={
                        <PrivateRoute path="schedule">
                            <Schedule />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/thingstodo"
                    element={
                        <PrivateRoute path="thingstodo">
                            <ThingsToDo />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/hotel"
                    element={
                        <PrivateRoute path="hotel">
                            <Hotel />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/payment"
                    element={
                        <PrivateRoute path="payment">
                            <Payment />
                        </PrivateRoute>
                    }
                />
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </>
    );
};

export default App;
