import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
    color: {
        background: '#F3F9FF',
        lightBlue: '#CEE6FF',
        blue: '#0080FF',
        transparentBlue: 'rgba(0, 128, 255, 0.15)',
        darkTransparentBlue: 'rgba(0, 128, 255, 0.75)'
    },
    font: {
        medium: 'Larsseit-Medium, sans-serif',
        bold: 'Larsseit-ExtraBold, sans-serif',
        boldItalic: 'Larsseit-ExtraBoldItalic, sans-serif'
    },
    fontSize: {
        small: '14px',
        medium: '18px',
        large: '20px',
        huge: '48px'
    },
    fontWeight: {
        light: '400',
        medium: '600',
        bold: '700',
        heavy: '800'
    },
    shadow: {
        light: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        dark: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
    }
};

const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
